<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">Kommentar</th>
          <th class="text-left">Autor</th>
          <th class="text-left">Datum</th>
          <th class="text-right"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in comments"
          :key="item.id"
        >
          <td>{{ item.msg }}</td>
          <td>{{ $root.getUserName(item.userId) }}</td>
          <td>{{ item.ts | date }}</td>
          <td width="24" class="text-right">
            <v-btn icon :loading="item._loading" @click="restore(item)">
              <v-icon title="Wiederherstellen">mdi-delete-off</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'DeletedComments',
  data () {
    return {
      comments: null
    }
  },
  methods: {
    async restore (comment) {
      comment._loading = true
      const deleteCommentFunc = this.$fb.fn.httpsCallable('deleteComment')
      const response = await deleteCommentFunc({
        id: comment.id,
        postId: comment.postId,
        restore: true
      })
      comment._loading = false
      if (response.data.ok) {
        this.comments = this.comments.filter(p => p.id !== comment.id)
      }
    }
  },
  created () {
    this.$fb.db.collectionGroup('comments')
      .where('_deleted', '==', true)
      .get()
      .then(snap => {
        const comments = []
        snap.forEach(doc => {
          comments.push({
            id: doc.id,
            ...doc.data(),
            _loading: false
          })
        })
        comments.sort((a, b) => b.ts.seconds - a.ts.seconds)
        this.comments = comments
      })
  }
}
</script>

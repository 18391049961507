<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab>Posts</v-tab>
      <v-tab>Kommentare</v-tab>
      <v-tab>Events</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <deleted-posts/>
      </v-tab-item>
      <v-tab-item>
        <deleted-comments/>
      </v-tab-item>
      <v-tab-item>
        <deleted-events/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import DeletedComments from '@/components/admin/DeletedComments'
import DeletedEvents from '@/components/admin/DeletedEvents'
import DeletedPosts from '@/components/admin/DeletedPosts'

export default {
  name: 'WastebinAdmin',
  components: { DeletedComments, DeletedEvents, DeletedPosts },
  data () {
    return {
      tab: null
    }
  }
}
</script>

<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">Titel</th>
          <th class="text-left">Autor</th>
          <th class="text-left">Datum (Beginn)</th>
          <th class="text-right"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in events"
          :key="item.id"
        >
          <td>{{ item.title }}</td>
          <td>{{ $root.getUserName(item.userId) }}</td>
          <td>{{ item.begin | date }}</td>
          <td width="24" class="text-right">
            <v-btn icon :loading="item._loading" @click="restore(item)">
              <v-icon title="Wiederherstellen">mdi-delete-off</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'DeletedEvents',
  data () {
    return {
      events: null
    }
  },
  methods: {
    async restore (event) {
      event._loading = true
      const deleteEventFunc = this.$fb.fn.httpsCallable('deleteEvent')
      const response = await deleteEventFunc({
        id: event.id,
        restore: true
      })
      event._loading = true
      if (response.data.ok) {
        this.events = this.events.filter(p => p.id !== event.id)
      }
    }
  },
  created () {
    this.$fb.db.collection('events')
      .where('_deleted', '==', true)
      .get()
      .then(snap => {
        const events = []
        snap.forEach(doc => {
          events.push({
            id: doc.id,
            ...doc.data(),
            _loading: false
          })
        })
        events.sort((a, b) => b.ts.seconds < a.ts.seconds ? -1 : 1)
        this.events = events
      })
  }
}
</script>
